export enum AgreementType {
  NFA = "New Frame Agreement",
  SA = "Service Agreement",
  MFA = "Mobile Frame Agreement",
  RAM = "Ram agreements",
}

export type SiteVersion = "mc_online" | "b2b_order" | "legacy";

// action
export enum IdentifyState {
  INITIATED = "INITIATED",
  CLICK = "CLICK",
  COMPLETED = "COMPLETED",
}

export enum ExpressCheckoutOption {
  INITIATE = "initiate",
  IDENTIFY_BANKID = "identify_bankid",
  VERIFIED_BANKID = "verified_bankid",
}

export enum OrderCompanySelectorState {
  COMPANY_SELECTOR_VIEW = "company_selector_view",
  COMPANY_SELECTED = "company_selected",
}

// label
export enum IdentifyType {
  GUEST_ACCOUNT = "GUEST_ACCOUNT",
  BANK_ID = "BANK_ID",
  VERIFY_ORGANISATION = "VERIFY_ORGANISATION",
  CHANGE_ORGANISATION = "CHANGE_ORGANISATION",
}

export enum Salesflow {
  MB = "MyBusiness-Salesflow",
  OP = "B2B-Open-Pages-Salesflow",
}
export enum Ga4AgreementType {
  NFA = "new_frame_agreement",
  MFA = "mobile_frame_agreement",
  SA = "service_agreement",
  RAM = "ram_agreements",
}
