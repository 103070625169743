/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddBillingAccountDTO } from '../models/AddBillingAccountDTO';
import type { AddBillingAccountRequestDTO } from '../models/AddBillingAccountRequestDTO';
import type { GetBillingAccountDTO } from '../models/GetBillingAccountDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BillingAccountsControllerService {

    /**
     * @param scopeId
     * @param tscid
     * @param category
     * @returns GetBillingAccountDTO OK
     * @throws ApiError
     */
    public static getBillingAccountsForTscid(
        scopeId: string,
        tscid: string,
        category?: Array<'MOBILE' | 'BROADBAND' | 'BILLING_ACCOUNT'>,
    ): CancelablePromise<Array<GetBillingAccountDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/organizations/{tscid}/billingaccounts',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            query: {
                'category': category,
            },
        });
    }

    /**
     * @param scopeId
     * @param tscid
     * @param requestBody
     * @returns AddBillingAccountDTO OK
     * @throws ApiError
     */
    public static addBillingAccount(
        scopeId: string,
        tscid: string,
        requestBody: AddBillingAccountRequestDTO,
    ): CancelablePromise<AddBillingAccountDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/scope/{scopeId}/organizations/{tscid}/billingaccounts',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param scopeId
     * @param tscid
     * @param billingAccountNumber
     * @returns GetBillingAccountDTO OK
     * @throws ApiError
     */
    public static getBillingAccount1(
        scopeId: string,
        tscid: string,
        billingAccountNumber: string,
    ): CancelablePromise<GetBillingAccountDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/organizations/{tscid}/billingaccounts/{billingAccountNumber}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'billingAccountNumber': billingAccountNumber,
            },
        });
    }

    /**
     * @param column
     * @param scopeId
     * @param tscid
     * @param billingAccountNumber
     * @returns GetBillingAccountDTO OK
     * @throws ApiError
     */
    public static getBillingAccountByColumn(
        column: 'ACCOUNT_NUMBER' | 'BILLING_ACCOUNT_NUMBER',
        scopeId: string,
        tscid: string,
        billingAccountNumber: string,
    ): CancelablePromise<GetBillingAccountDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/organizations/{tscid}/billingaccounts/{billingAccountNumber}/bycolumn',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'billingAccountNumber': billingAccountNumber,
            },
            query: {
                'column': column,
            },
        });
    }

    /**
     * @param scopeId
     * @param tscid
     * @returns string OK
     * @throws ApiError
     */
    public static getBillingAccountEmails(
        scopeId: string,
        tscid: string,
    ): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/organizations/{tscid}/billingaccounts/emails',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
        });
    }

}