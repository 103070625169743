<template>
  <div
    t-id="b2b-order-products-confirmation"
    data-test-id="b2b-order-products-confirmation"
    class="order-products-confirmation"
  >
    <b2x-modal :is-open="showSurveyModal" @closeModal="handleCloseSurvey">
      <span slot="modal-body">
        <div id="mco_order_confirmation_survey"></div>
      </span>
    </b2x-modal>
    <b2b-layout
      :heading="t('TITLE')"
      :back-link-label="t('BACK_TO_PRODUCTS_AND_SERVICES')"
      :back-link-href="`/foretag/mybusiness/${scopeId}/bestall/produkter-tjanster`"
      centered-heading
    >
      <telia-grid>
        <telia-row class="order-products-confirmation__row">
          <telia-col width="12" width-md="12" width-lg="7">
            <div class="order-products-confirmation__section-wrapper">
              <telia-skeleton v-if="loading" class="skeleton__manage" />
              <section v-else>
                <card>
                  <manage-links
                    t-id="manage-links"
                    :scope-id="scopeId"
                    :order-number="mainOrderNumber"
                  />
                </card>
              </section>
              <telia-skeleton v-if="loading && orderConfirmationSurveyEnabled" class="skeleton__survey" />
              <section v-else-if="!loading && orderConfirmationSurveyEnabled">
                <card>
                  <telia-heading tag="h2" variant="title-200" class="survey-heading">
                    {{ t("SURVEY.HEADING") }}
                  </telia-heading>
                  <telia-button
                    t-id="show-survey-button"
                    variant="secondary"
                    size="md"
                    :text="t('SURVEY.LINK')"
                    @click="handleShowSurvey"
                  />
                </card>
              </section>
              <telia-skeleton v-if="loading" class="skeleton__order-information" />
              <section v-else-if="orders">
                <card>
                  <order-information
                    t-id="order-information"
                    :order="orders"
                    :invoice-account-info="billingAccounts"
                  />
                </card>
              </section>
            </div>
          </telia-col>
          <telia-col width="12" width-md="12" width-lg="5">
            <div class="order-products-confirmation__section-wrapper">
              <telia-skeleton v-if="loading" class="skeleton__products" />
              <section v-else-if="orders">
                <card>
                  <product-listing
                    t-id="product-listing"
                    :order="orders"
                    :process-lines="processLinesWithOrderNumber"
                  />
                </card>
              </section>
            </div>
          </telia-col>
        </telia-row>
      </telia-grid>
    </b2b-layout>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, computed } from "vue";
import { useFlag } from "@unleash/proxy-client-vue";
import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";
import { corpProductOrder, corpBillingAccounts } from "@telia/b2b-rest-client";
import { OrdersUI } from "@telia/b2b-rest-client/dist/corp-product-order";
import { GetBillingAccountDTO } from "@telia/b2b-rest-client/dist/corp-billing-accounts";
import { currentLanguage } from "@telia/b2b-i18n";
import { translateMixin, translateSetup } from "./locale";
import { getTscidFromUrl, getBasketIdFromUrl } from "./helpers/urlParams";
import { useGa4Helper } from "./helpers/gaHelper";
import OrderInformation from "./components/OrderInformation.vue";
import ProductListing from "./components/ProductListing.vue";
import Card from "./components/Card.vue";
import ManageLinks from "./components/ManageLinks.vue";

// TODO: Feature flags. Cleanup when appropriate.
const orderConfirmationSurveyEnabled = useFlag("b2b-mco-order-confirmation-survey");

const MAX_RETRIES = 3;
const DELAY_MS = 4000;

const { trackSurveyInitiate } = useGa4Helper();

translateSetup(["mybusiness"]);
const t = translateMixin.methods.t;
const tscId = ref("");
const scopeId = ref("");
const basketId = ref("");
const orders = ref<OrdersUI>();
const billingAccounts = ref<GetBillingAccountDTO[]>([]);
const loading = ref<Boolean>(false);
const retry = ref(0);
const showSurveyModal = ref(false);

const processLinesWithOrderNumber = computed(() => {
  return orders.value?.orders?.flatMap((order) => {
    if (order.processLines?.length) {
      return order.processLines.map((line) => ({
        ...line,
        orderNumber: order.orderNumber ?? "",
      }));
    }
    return [];
  });
});

const mainOrderNumber = computed(() => {
  const orderNumbers = orders.value?.orders
    ?.filter((order) => order.orderNumber)
    .map((order) => order.orderNumber)
    .sort();
  return orderNumbers?.[0] ?? "";
});

onMounted(async () => {
  loading.value = true;
  scopeId.value = await getScopeIdOrThrow();
  tscId.value = getTscidFromUrl();
  basketId.value = getBasketIdFromUrl();

  while (retry.value < MAX_RETRIES) {
    const response = await fetchOrder();
    if (response) break;
    await new Promise((resolve) => setTimeout(resolve, DELAY_MS));
  }

  await fetchBillingAccounts();

  window.addEventListener("MDigital_Form_Close_No_Submit", () => {
    handleCloseSurvey();
  });
  window.addEventListener("MDigital_ThankYou_Close", () => {
    handleCloseSurvey();
  });

  loading.value = false;
});

async function fetchOrder(): Promise<Boolean> {
  const lang = currentLanguage() === "sv" ? "SV" : "EN";
  try {
    const response: OrdersUI = await corpProductOrder.OrderControllerService.getOrders(
      scopeId.value,
      tscId.value,
      basketId.value,
      lang
    );
    orders.value = response;

    return true;
  } catch {
    retry.value += 1;
    return false;
  }
}
async function fetchBillingAccounts(): Promise<void> {
  const billingAccountsFromTscid =
    await corpBillingAccounts.BillingAccountsControllerService.getBillingAccountsForTscid(
      scopeId.value,
      tscId.value
    );
  const accounts = orders.value?.billingAccount
    ? [orders.value?.billingAccount]
    : orders.value?.billingAccounts;

  billingAccounts.value = billingAccountsFromTscid?.filter(
    (account) =>
      accounts?.some((account2) => account2.billingAccountNumber === account.accountNumber)
  );
}
function handleShowSurvey() {
  showSurveyModal.value = true;
  trackSurveyInitiate("click", "Tell us what you thought about the purchase");
  // @ts-ignore
  window.KAMPYLE_ONSITE_SDK.updatePageView();
}

function handleCloseSurvey() {
  showSurveyModal.value = false;
}
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/colors/variables";
@import "node_modules/@teliads/components/foundations/spacing/variables";

.order-products-confirmation {
  background-color: $telia-gray-50;
  height: 100%;

  &__row {
    display: flex;
    row-gap: $telia-spacing-32;
  }

  &__section-wrapper {
    display: flex;
    flex-direction: column;
    gap: $telia-spacing-32;
  }
}

.skeleton {
  &__manage {
    height: 21rem;
  }
  &__survey {
    height: 18rem;
  }
  &__order-information {
    height: 40rem;
  }
  &__products {
    height: 35rem;
  }
}

.survey-heading {
  padding-bottom: $telia-spacing-24;
}
</style>
