<template>
  <div class="emn-subscription-item">
    <telia-p>{{ item?.product.name }}</telia-p>
    <telia-p variant="additional-100">
      {{ t("EMN.SITE", { site: item?.product.siteName || "" }) }}
    </telia-p>
    <telia-p v-if="item?.product.ipRangeStart" variant="additional-100">
      {{ t("EMN.IP_RANGE_START", { ipRange: item?.product.ipRangeStart }) }}
    </telia-p>
    <telia-p variant="additional-100">
      {{ t("EMN.QUANTITY", { quantity: item?.quantity }) }}
    </telia-p>
  </div>
</template>

<script setup lang="ts">
import {
  BasketEmnSubscriptionLineUI,
} from "@telia/b2b-rest-client/dist/corp-product-order";
import { translateMixin } from "../locale";

interface Props {
  item: BasketEmnSubscriptionLineUI;
}

defineProps<Props>();

const t = translateMixin.methods.t;
</script>

<style lang="scss" scoped></style>
