<template>
  <div class="total-cost">
    <telia-heading class="heading" tag="h2" variant="title-100">{{
      t("TOTAL_COST")
    }}</telia-heading>
    <div class="total-price-column">
      <telia-p v-if="order.totalOneTimeFee">
        <div class="total-price-fee-wrapper">
          <span>{{ t("ONE_TIME_FEE") }}</span>
          <span>{{ addonOneTimeFee(order.totalOneTimeFee) }}</span>
        </div>
      </telia-p>
      <telia-p v-if="order.totalRecurringFee">
        <div class="total-price-fee-wrapper">
          <span>{{ t("MONTHLY_FEE") }}</span>
          <span>{{ addonRecurringFeeInfo(order.totalRecurringFee) }}</span>
        </div>
      </telia-p>
      <telia-p class="vat-disclaimer" variant="additional-100">{{ t("EXCLUDED_VAT") }}</telia-p>
    </div>
  </div>
</template>
<script setup lang="ts">
import { OrdersUI } from "@telia/b2b-rest-client/dist/corp-product-order";
import { addonRecurringFeeInfo, addonOneTimeFee } from "../helpers/formatUtils";
import { translateMixin } from "../locale";

interface Props {
  order: OrdersUI;
}
defineProps<Props>();

const t = translateMixin.methods.t;
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/spacing/variables";

.total-cost {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-4;
}
.total-price-column {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-4;
}

.total-price-fee-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
