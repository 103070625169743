<template>
  <div class="product-listing">
    <telia-heading tag="h2" variant="title-200">{{ t("PRODUCTS_AND_SERVICES") }}</telia-heading>
    <div class="product-listing__column">
      <div class="product-listing__row" v-for="combinedItem in combinedItems">
        <CombinedItems
          v-if="combinedItem"
          :combined-items="combinedItem"
          :process-lines="processLines"
        />
      </div>
      <div class="product-listing__row" v-for="subscription in subscriptions">
        <SubscriptionItem
          v-if="subscription"
          :item="subscription"
          :process-lines="processLines"
          :switchboard-lines="switchboards"
        />
      </div>
      <div class="product-listing__row" v-for="emnSubscription in emnSubscriptions">
        <EmnSubscriptionItem
          v-if="emnSubscription"
          :item="emnSubscription"
        />
      </div>
      <div class="product-listing__row" v-for="hardware in hardwares">
        <HardwareItem v-if="hardware" :item="hardware" />
      </div>
      <div v-for="accessory in accessories">
        <HardwareItem v-if="accessory" :item="accessory" />
      </div>
    </div>
    <TotalCost :order="order" />
  </div>
</template>

<script setup lang="ts">
import { translateMixin } from "../locale";
import { OrdersUI } from "@telia/b2b-rest-client/dist/corp-product-order";
import { computed, onMounted, ref } from "vue";
import SubscriptionItem from "./SubscriptionItem.vue";
import EmnSubscriptionItem from "./EmnSubscriptionItem.vue";
import HardwareItem from "./HardwareItem.vue";
import CombinedItems from "./CombinedItems.vue";
import TotalCost from "./TotalCost.vue";
import {
  BasketSubscriptionLineUI,
  BasketHardwareLineUI,
  BasketEmnSubscriptionLineUI,
} from "@telia/b2b-rest-client/dist/corp-product-order";
import { ProcessLinesWithOrderNumber } from "../data-types";

type CombinedItem = {
  subscription: BasketSubscriptionLineUI;
  hardware: BasketHardwareLineUI;
};

interface Props {
  order: OrdersUI;
  processLines?: ProcessLinesWithOrderNumber[];
}

const t = translateMixin.methods.t;
const combinedItems = ref<CombinedItem[]>();
const subscriptions = ref<BasketSubscriptionLineUI[]>([]);
const emnSubscriptions = ref<BasketEmnSubscriptionLineUI[]>([]);
const hardwares = ref<BasketHardwareLineUI[]>([]);

onMounted(() => {
  if (!props?.order?.orders) return;
  subscriptions.value = props.order.orders.map((order) => order.subscriptionLines || []).flat();
  emnSubscriptions.value = props.order.orders.map((order) => order.emnSubscriptionLines || []).flat();
  hardwares.value = props.order.orders.map((order) => order.hardwareLines || []).flat();
  const combined: CombinedItem[] = [];

  if (!subscriptions.value && !hardwares.value) return;

  hardwares.value?.forEach((hardware, hardwareIndex) => {
    for (
      let subscriptionIndex = 0;
      subscriptionIndex < subscriptions.value.length;
      subscriptionIndex++
    ) {
      if (hardware.relationId === subscriptions?.value[subscriptionIndex].relationId) {
        combined.push({ hardware, subscription: subscriptions?.value[subscriptionIndex] });
        subscriptions.value.splice(subscriptionIndex, 1);
        hardwares.value.splice(hardwareIndex, 1);
      }
    }
  });
  combinedItems.value = combined;
});

const props = defineProps<Props>();

const accessories = computed(() => {
  return props.order.orders?.map((orderline) => orderline.accessoryLines).flat();
});

const switchboards = computed(() => {
  return props?.order?.orders?.[0].switchboardUserLines ?? [];
});
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/spacing/variables";

.product-listing {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-24;

  &__row {
    display: flex;
    flex-direction: row;
  }
  &__column {
    display: flex;
    flex-direction: column;
    gap: $telia-spacing-16;
  }
}
</style>
