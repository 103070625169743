import { Environment } from "./types";

export const APP_NAME = "telia-unleash-client";
export const UNLEASH_EDGE_PROXY_URL = "/.api/corp-unleash-edge/api/proxy";
export const LOCAL_EDGE_PROXY_URL = "http://localhost:3063/api/proxy";
export const DEV_CLIENT_API_TOKEN =
  "*:test.ff3865c2e052c66a937093d69c20d594fbc1c3d77e8f01d6f95db150";
export const TEST_CLIENT_API_TOKEN =
  "*:test.d20523d7cb82a49d093b61645fb7e6a0fb39a12af299832f32c0fe14";
export const PROD_CLIENT_API_TOKEN =
  "*:production.036933f766bd43fcd13df237ee4dd0fdbe445892d2a2e3012ed3fcc3";

export const ENVIRONMENT_TO_CLIENT_API_TOKEN_MAP = {
  [Environment.DEV]: DEV_CLIENT_API_TOKEN,
  [Environment.TEST]: TEST_CLIENT_API_TOKEN,
  [Environment.PROD]: PROD_CLIENT_API_TOKEN,
} as const;
