/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BillingTermsDTO } from '../models/BillingTermsDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BillingTermsControllerService {

    /**
     * @returns BillingTermsDTO OK
     * @throws ApiError
     */
    public static getBillingTerms(): CancelablePromise<BillingTermsDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/billing-terms',
        });
    }

}