/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AgreementBillingTermsDTO } from '../models/AgreementBillingTermsDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AgreementBillingTermsControllerService {

    /**
     * @param scopeId
     * @param tscid
     * @returns AgreementBillingTermsDTO OK
     * @throws ApiError
     */
    public static getBillingTermsFromAgreement(
        scopeId: string,
        tscid: string,
    ): CancelablePromise<AgreementBillingTermsDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/organizations/{tscid}/agreement-billing-terms',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
        });
    }

}