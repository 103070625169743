/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BillingAccountDTO } from '../models/BillingAccountDTO';
import type { CreateBillingAccountRequestDTO } from '../models/CreateBillingAccountRequestDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AccountManagementControllerService {

    /**
     * @param scopeId
     * @param tscid
     * @returns BillingAccountDTO OK
     * @throws ApiError
     */
    public static getBillingAccountsForTscid1(
        scopeId: string,
        tscid: string,
    ): CancelablePromise<Array<BillingAccountDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/organizations/{tscid}/account-management/billing-accounts',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
        });
    }

    /**
     * @param scopeId
     * @param tscid
     * @param requestBody
     * @returns BillingAccountDTO OK
     * @throws ApiError
     */
    public static createBillingAccountForTscid(
        scopeId: string,
        tscid: string,
        requestBody: CreateBillingAccountRequestDTO,
    ): CancelablePromise<BillingAccountDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/scope/{scopeId}/organizations/{tscid}/account-management/billing-accounts',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param tscid
     * @param requestBody
     * @returns BillingAccountDTO OK
     * @throws ApiError
     */
    public static createBillingAccountForTscid1(
        tscid: string,
        requestBody: CreateBillingAccountRequestDTO,
    ): CancelablePromise<BillingAccountDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/organizations/{tscid}/account-management/billing-accounts',
            path: {
                'tscid': tscid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}