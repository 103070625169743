<template>
  <div class="hardware-item">
    <div>
      <telia-p>{{ item?.product.name }}</telia-p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { BasketHardwareLineUI } from "../data-types";
import { BasketAccessoryLineUI } from "@telia/b2b-rest-client/dist/corp-product-order";
import { translateMixin } from "../locale";

interface Props {
  item: BasketHardwareLineUI | BasketAccessoryLineUI;
}

defineProps<Props>();

const t = translateMixin.methods.t;
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/spacing/variables";
.hardware-item {
  display: flex;
  flex-direction: row;
  gap: $telia-spacing-32;
}
</style>
