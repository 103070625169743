<template>
  <div class="subscription-item">
    <telia-p>{{ item?.product.name }}</telia-p>
    <telia-p v-if="relatedSwitchboard" variant="additional-100">
      {{ relatedSwitchboard.product.name }}
    </telia-p>

    <telia-p v-if="numberTransfer" variant="additional-100" t-id="number-transfer-info">
      {{ t("ORDER_NUMBER_FOR_TRANSFER") }} {{ numberTransfer?.orderNumber }}
    </telia-p>
  </div>
</template>

<script setup lang="ts">
import {
  BasketSubscriptionLineUI,
  BasketSwitchboardUserLineUI,
} from "@telia/b2b-rest-client/dist/corp-product-order";
import { translateMixin } from "../locale";
import { computed } from "vue";
import { ProcessLinesWithOrderNumber } from "../data-types";

interface Props {
  item: BasketSubscriptionLineUI;
  processLines?: ProcessLinesWithOrderNumber[];
  switchboardLines?: BasketSwitchboardUserLineUI[];
}

const props = defineProps<Props>();

const t = translateMixin.methods.t;

const numberTransfer = computed(() => {
  return props.processLines?.find((line) => line.relationId === props.item.relationId);
});
const relatedSwitchboard = computed(() => {
  return props.switchboardLines?.find((line) => line.relationId === props.item.relationId);
});
</script>

<style lang="scss" scoped></style>
