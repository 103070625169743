import { track } from "@telia/b2x-google-analytics";
import {
  buildCartPayloadConfiguration,
  buildPayloadBeginCheckout,
  buildPayloadBrowse,
  buildPayloadConfiguration,
  buildPayloadFunnelFail,
  buildPayloadPurchase,
  buildPayloadRemoveProductFromCart,
  buildSalesflowInitiatePayload,
  buildCheckoutStepPayload,
  buildSurveyInitiatePayload,
  buildLoginInitiatePayload,
  buildExpressCheckoutPayload,
  buildOrderCompanySelectorPayload,
} from "./ga4utils";
import {
  TrackAddToCartGa4Args,
  TrackBeginCheckoutGa4Args,
  TrackFunnelFailGa4Args,
  TrackProductClickGa4Args,
  TrackProductListGa4Args,
  TrackProductViewGa4Args,
  TrackPurchaseGa4Args,
  TrackRemoveFromCartGa4Args,
  TrackSalesflowInitiateGa4Args,
  TrackShippingInfoGa4Args,
  TrackPaymentInfoGa4Args,
  TrackCheckoutProgressGa4Args,
  TrackViewCartArgs,
  TrackSurveyInitiateGa4Args,
  TrackLoginInitiateGa4Args,
  TrackExpressCheckoutGa4Args,
  TrackOrderCompanySelectorGa4Args,
} from "./interfaces";

async function trackProductList(trackProductListArgs: TrackProductListGa4Args): Promise<void> {
  if (trackProductListArgs.products.length > 0) {
    track("view_item_list", buildPayloadBrowse(trackProductListArgs));
  }
}

async function trackSelectItem(trackSelectItemArgs: TrackProductClickGa4Args) {
  track(
    "select_item",
    buildPayloadBrowse({ ...trackSelectItemArgs, products: [trackSelectItemArgs.product] })
  );
}

async function trackViewItem(trackViewItemArgs: TrackProductViewGa4Args): Promise<void> {
  track(
    "view_item",
    buildPayloadConfiguration({ ...trackViewItemArgs, products: [trackViewItemArgs.product] })
  );
}

function trackBeginCheckout(trackBeginCheckoutArgs: TrackBeginCheckoutGa4Args) {
  track("begin_checkout", buildPayloadBeginCheckout(trackBeginCheckoutArgs));
}

async function trackAddToCart(trackAddToCartArgs: TrackAddToCartGa4Args): Promise<void> {
  track("add_to_cart", buildCartPayloadConfiguration(trackAddToCartArgs));
}
async function trackPurchase(trackPurchaseArgs: TrackPurchaseGa4Args): Promise<void> {
  track("purchase", buildPayloadPurchase(trackPurchaseArgs));
}

async function trackViewCart(trackPurchaseArgs: TrackViewCartArgs): Promise<void> {
  track("view_cart", buildCartPayloadConfiguration(trackPurchaseArgs));
}

async function trackRemoveFromCart(
  TrackRemoveFromCartArgs: TrackRemoveFromCartGa4Args
): Promise<void> {
  track("remove_from_cart", buildPayloadRemoveProductFromCart(TrackRemoveFromCartArgs));
}

async function trackInitiateSalesflow(trackInitiateSalesflow: TrackSalesflowInitiateGa4Args) {
  track("salesflow_initiate", buildSalesflowInitiatePayload(trackInitiateSalesflow));
}

async function trackFunnelFail(trackFunnelFail: TrackFunnelFailGa4Args): Promise<void> {
  track("ecommerce_funnel_fail", buildPayloadFunnelFail(trackFunnelFail));
}

async function trackShippingInfo(trackShippingInfoArgs: TrackShippingInfoGa4Args): Promise<void> {
  track("add_shipping_info", buildCheckoutStepPayload(trackShippingInfoArgs));
}

async function trackPaymentInfo(trackPaymentInfoArgs: TrackPaymentInfoGa4Args): Promise<void> {
  track("add_payment_info", buildCheckoutStepPayload(trackPaymentInfoArgs));
}

async function trackCheckoutProgress(
  trackCheckoutProgressArgs: TrackCheckoutProgressGa4Args
): Promise<void> {
  track("checkout_progress", buildCheckoutStepPayload(trackCheckoutProgressArgs));
}

async function trackSurveyInitiate(
  trackSurveyInitiateArgs: TrackSurveyInitiateGa4Args
): Promise<void> {
  track("survey_initiate", buildSurveyInitiatePayload(trackSurveyInitiateArgs));
}

async function trackLoginInitiate(
  trackLoginInitiateArgs: TrackLoginInitiateGa4Args
): Promise<void> {
  track("login_initiate", buildLoginInitiatePayload(trackLoginInitiateArgs));
}

async function trackExpressCheckout(
  trackExpressCheckoutArgs: TrackExpressCheckoutGa4Args
): Promise<void> {
  track("b2b_order_express_checkout", buildExpressCheckoutPayload(trackExpressCheckoutArgs));
}

async function trackOrderCompanySelector(
  trackOrderCompanySelectorArgs: TrackOrderCompanySelectorGa4Args
): Promise<void> {
  track(
    "b2b_order_company_selector",
    buildOrderCompanySelectorPayload(trackOrderCompanySelectorArgs)
  );
}

export default {
  trackAddToCart,
  trackBeginCheckout,
  trackProductList,
  trackPurchase,
  trackRemoveFromCart,
  trackSelectItem,
  trackViewItem,
  trackInitiateSalesflow,
  trackFunnelFail,
  trackShippingInfo,
  trackPaymentInfo,
  trackCheckoutProgress,
  trackViewCart,
  trackSurveyInitiate,
  trackLoginInitiate,
  trackExpressCheckout,
  trackOrderCompanySelector,
};
