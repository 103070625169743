<template>
  <div class="manage-links">
    <div v-if="!orderNumber" class="manage-links__error-wrapper">
      <telia-heading tag="h2" variant="title-200" t-id="error-heading">
        {{ t("RECEIVED_YOUR_ORDER") }}
      </telia-heading>
      <div class="manage-links__error-wrapper__notification-wrapper">
        <telia-notification status="information" heading-tag="h3">
          <telia-p slot="heading">
            <strong>{{ t("ORDER_ERROR.TITLE") }}</strong>
          </telia-p>
          <telia-p slot="content">
            {{ t("ORDER_ERROR.BODY") }}
          </telia-p>
        </telia-notification>
      </div>
    </div>

    <telia-heading v-else t-id="order-number" tag="h2" variant="title-200">
      {{ t("ORDER_NUMBER") }} {{ orderNumber }}
    </telia-heading>
    <telia-p> {{ t("ORDER_CONFIRMATION_HAS_BEEN_SENT") }}</telia-p>

    <div class="manage-links__menu-item">
      <telia-cta-link :href="`/foretag/mybusiness/${scopeId}/bestall/alla-bestallningar`">
        {{ t("LINKS.TRACK_THE_ORDER") }}
      </telia-cta-link>

      <telia-cta-link
        variant="secondary"
        :href="`/foretag/mybusiness/${scopeId}/hantera/produkter-tjanster/telefoni`"
      >
        {{ t("LINKS.MANAGE_SUBSCRIPTIONS") }}
      </telia-cta-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { translateMixin } from "../locale";

interface Props {
  scopeId: String;
  orderNumber: String;
}

const props = defineProps<Props>();

const t = translateMixin.methods.t;
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/spacing/variables";
@import "node_modules/@teliads/components/foundations/colors/variables";
.manage-links {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-24;

  &__menu-item {
    display: flex;
    align-items: center;
    gap: $telia-spacing-16;
  }

  &__error-wrapper {
    display: flex;
    flex-direction: column;
    gap: $telia-spacing-24;

    &__notification-wrapper {
      max-width: 55rem;
    }
  }
}
</style>
