import { currentLanguage } from "@telia/b2b-i18n";
import { translate as t } from "@telia/b2b-i18n";

const formatCurrency = (amount: number) => {
  return new Intl.NumberFormat(currentLanguage(), {
    style: "currency",
    currency: "SEK",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(amount);
};

const addonOneTimeFee = (oneTimeFee?: string) => {
  return shouldDisplayNumber(oneTimeFee)
    ? t("PRICE", {
        fee: formatCurrency(Number(oneTimeFee)),
      })
    : "";
};

const addonRecurringFeeInfo = (recurringFee?: string) => {
  return shouldDisplayNumber(recurringFee)
    ? t("PRICE_PER_MONTH", {
        fee: formatCurrency(Number(recurringFee)),
      })
    : "";
};
const reccuringFeePriceSummary = (recurringFee?: number) => {
  return recurringFee
    ? t("pricing.pricePerMonthShort", {
      fee: formatCurrency(Number(recurringFee)),
      })
    : "";
};

const shouldDisplayNumber = (number?: string) => {
  const numberAmount = Number(number);
  return !isNaN(numberAmount);
};

function subscriptionNameFormat(subscriptionName: string, bundleName?: string) {
  return subscriptionName.replace(`${bundleName} `, "");
}

export {
  subscriptionNameFormat,
  addonOneTimeFee,
  addonRecurringFeeInfo,
  formatCurrency,
  reccuringFeePriceSummary,
};
