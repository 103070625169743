import { match } from "path-to-regexp";
interface Match {
  params: Params;
}
interface Params {
  tscid: string;
  scopeId: string;
  basketId: string;
}

export function getTscidFromUrl(url = window.location.pathname): string {

  const { params } = match("/foretag/mybusiness/:scopeId/bestall/:tscid/din-bestallning/:basketId")(url) as Match;

  return params.tscid;
}

export function getBasketIdFromUrl(url = window.location.pathname): string {

  const { params } = match("/foretag/mybusiness/:scopeId/bestall/:tscid/din-bestallning/:basketId")(url) as Match;

  return params.basketId;
}
