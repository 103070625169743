export enum Environment {
  DEV,
  TEST,
  PROD,
}

export type AxiosResponse<T> = {
  data: T;
  status: number;
  statusText: string;
  headers: {
    [key: string]: string;
  };
};

export type GetUserResponse = {
  domain: string;
  domainId: string;
  result: string;
};

export type AxiosHeaders = {
  headers: {
    [key: string]: string;
  };
};

export type User = {
  id?: string;
  domain?: string;
};

export type SecureLevel = {
  authLevel?: number;
  authType?: string;
};

export type Scope = {
  id?: string;
  name?: string;
  groupId?: number;
  actions?: Actions;
};

export type Actions = {
  available?: Array<string>;
  unavailable?: Array<UnavailableAction>;
};
export type UnavailableAction = {
  action?: string;
  reason?: string;
};

export type GetScopeDetailsResponse = {
  user?: User;
  secureLevel?: SecureLevel;
  scope?: Scope;
};
