<template>
  <div class="order-information">
    <telia-heading tag="h2" variant="title-200">{{ t("DETAILS") }}</telia-heading>

    <div class="order-information__row">
      <div>
        <div class="order-information__title-wrapper">
          <telia-p>
            <strong> {{ t("DELIVERY_ADDRESS") }}</strong>
          </telia-p>
        </div>

        <telia-p>{{ order?.delivery?.businessName }}</telia-p>
        <telia-p>{{ order?.delivery?.street }} {{ order?.delivery?.streetNumber }}</telia-p>
        <telia-p>{{ order?.delivery?.postalCode }} {{ order?.delivery?.city }}</telia-p>
      </div>
      <div>
        <div class="order-information__title-wrapper">
          <telia-p>
            <strong>{{ t("CONTACT") }}</strong>
          </telia-p>
        </div>
        <telia-p>
          {{ order?.contactDetails?.firstName }} {{ order?.contactDetails?.lastName }}
        </telia-p>
        <telia-p>{{ order?.contactDetails?.emailAddress }}</telia-p>
        <telia-p>{{ order?.contactDetails?.phone }}</telia-p>
        <telia-p>{{ order?.contactDetails?.reference }}</telia-p>
      </div>
      <div v-for="account in invoiceAccountInfo" class="invoice-account">
        <div class="order-information__title-wrapper">
          <telia-p>
            <strong>{{ t("BILLING_ACCOUNT") }}</strong>
          </telia-p>
        </div>
        <telia-p>{{ account.accountNumber }}</telia-p>
        <telia-p>{{ account.name }}</telia-p>
        <telia-p>{{ account.address }}</telia-p>
        <telia-p>{{ account.postalCode }} {{ account.city }}</telia-p>
        <telia-p>
          {{ t("DELIVERY_METHOD") }}
          {{ t(`DELIVERY_METHOD_OPTIONS.${account.deliveryMethod}`) }}
        </telia-p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { OrdersUI } from "@telia/b2b-rest-client/dist/corp-product-order";
import { GetBillingAccountDTO } from "@telia/b2b-rest-client/dist/corp-billing-accounts";
import { translateMixin } from "../locale";
import { addonRecurringFeeInfo } from "../helpers/formatUtils";

interface Props {
  order: OrdersUI;
  invoiceAccountInfo: GetBillingAccountDTO[];
}

const props = defineProps<Props>();
const t = translateMixin.methods.t;
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/spacing/variables";
@import "node_modules/@teliads/components/foundations/breakpoints/variables";
.order-information {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-24;
  flex: 1;
  flex-wrap: wrap;

  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    gap: $telia-spacing-32;
    @media (max-width: $telia-breakpoint-medium) {
      grid-template-columns: 1fr;
    }
  }

  &__title-wrapper {
    padding-bottom: $telia-spacing-4;
  }
}
</style>
