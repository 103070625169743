import {
  APP_NAME,
  ENVIRONMENT_TO_CLIENT_API_TOKEN_MAP,
  LOCAL_EDGE_PROXY_URL,
  UNLEASH_EDGE_PROXY_URL,
} from "./constants";
import { getNoScopeHeaders, getUserAndHeaders } from "./context";
import { getEnvironment } from "./environments";
import { Environment } from "./types";
import { getScopeId } from "@telia/b2b-customer-scope";

interface B2BUnleashConfig {
  appName: string;
  url: string;
  clientKey: string;
  refreshInterval: number;
  disableMetrics: boolean;
  context: B2BUnleashConfigContext;
}

interface B2BUnleashConfigContext {
  userId?: string;
  properties: Record<string, string>;
}

function getContextProperties(scopeId: string | null): Record<string, string> {
  return scopeId
    ? {
        scopeId: scopeId as string,
        authenticated: "true",
      }
    : { authenticated: "false" };
}

function getProxyUrl(env: Environment): string {
  const url: URL = new URL(window.location.href);
  return isDevelopmentEnvironment(env)
    ? LOCAL_EDGE_PROXY_URL
    : `${url.origin}${UNLEASH_EDGE_PROXY_URL}`;
}

function isDevelopmentEnvironment(env: Environment): boolean {
  return env == Environment.DEV;
}

function getRefreshIntervalForEnv(env: Environment): number {
  return env == Environment.PROD ? 300 : 0; // 5 min refresh interval in PROD, no refresh in DEV/TEST
}

export async function getConfig(appName: string = APP_NAME): Promise<B2BUnleashConfig> {
  const scopeId: string | null = await getScopeId();
  let headers;
  let domainId = null;

  if (!scopeId) {
    const res = await getNoScopeHeaders();
    headers = res.headers;
  } else {
    const res = await getUserAndHeaders(scopeId);
    headers = res.headers;
    domainId = res.user?.id;
  }
  const environment: Environment = getEnvironment({ headers });

  return {
    appName,
    url: getProxyUrl(environment),
    clientKey: ENVIRONMENT_TO_CLIENT_API_TOKEN_MAP[environment],
    refreshInterval: getRefreshIntervalForEnv(environment),
    disableMetrics: isDevelopmentEnvironment(environment),
    context: {
      properties: getContextProperties(scopeId),
      ...(domainId ? { userId: domainId! } : {}),
    },
  };
}
