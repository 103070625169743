import { ga4tracking } from "@telia/b2b-ecommerce-tracking";
import { AgreementType } from "@telia/b2b-ecommerce-tracking";
import { BaseGa4EventArgs } from "@telia/b2b-ecommerce-tracking/dist/interfaces";
import { computed } from "vue";

export const useGa4Helper = () => {
  const common = computed<BaseGa4EventArgs>(() => {
    return {
      agreementType: AgreementType.NFA, // TODO: How do we get this info in order confirmation?
      siteVersion: "mc_online",
      isLoggedIn: true,
      pageSegment: "mybusiness",
    };
  });

  function trackSurveyInitiate(clickAction = "click", clickText = "") {
    ga4tracking.trackSurveyInitiate({
      ...common.value,
      clickAction: clickAction,
      clickText: clickText,
    });
  }

  return {
    trackSurveyInitiate,
  };
};
