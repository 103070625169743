/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BillingAccountDTO } from '../models/BillingAccountDTO';
import type { ValidateBillingAccountBelongsToOrganizationDTO } from '../models/ValidateBillingAccountBelongsToOrganizationDTO';
import type { ValidateBillingAccountBelongsToOrganizationRequestDTO } from '../models/ValidateBillingAccountBelongsToOrganizationRequestDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AccountManagementInternalControllerService {

    /**
     * @param scopeId
     * @param requestBody
     * @returns ValidateBillingAccountBelongsToOrganizationDTO OK
     * @throws ApiError
     */
    public static validateOwnership(
        scopeId: string,
        requestBody: ValidateBillingAccountBelongsToOrganizationRequestDTO,
    ): CancelablePromise<ValidateBillingAccountBelongsToOrganizationDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/scope/{scopeId}/account-management/billing-accounts/validate-ownership',
            path: {
                'scopeId': scopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param scopeId
     * @param billingAccountId
     * @returns BillingAccountDTO OK
     * @throws ApiError
     */
    public static getBillingAccount(
        scopeId: string,
        billingAccountId: string,
    ): CancelablePromise<BillingAccountDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/account-management/billing-accounts/{billingAccountId}',
            path: {
                'scopeId': scopeId,
                'billingAccountId': billingAccountId,
            },
        });
    }

}