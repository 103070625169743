/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BillingAccountDTO } from '../models/BillingAccountDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BillingAccountsInternalControllerService {

    /**
     * @param billingAccountNumber
     * @returns BillingAccountDTO OK
     * @throws ApiError
     */
    public static getBillingAccount2(
        billingAccountNumber: string,
    ): CancelablePromise<BillingAccountDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/billingaccounts/{billingAccountNumber}',
            path: {
                'billingAccountNumber': billingAccountNumber,
            },
        });
    }

}