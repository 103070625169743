import { AgreementType, Ga4AgreementType, SiteVersion } from "./enums";
import {
  EcommerceItem,
  EcommerceListName,
  EcommerceValue,
  ConfigurationPayloadArgs,
  BrowsePayloadArgs,
  CheckoutPayloadArgs,
  CommonGa4Payload,
  DefaultPayloadArgs,
  TrackRemoveFromCartGa4Args,
  TrackSalesflowInitiateGa4Args,
  TrackPurchaseGa4Args,
  TrackFunnelFailGa4Args,
  TrackCheckoutGa4Args,
  TrackPaymentInfoGa4Args,
  TrackSurveyInitiateGa4Args,
  Ga4Product,
  TrackLoginInitiateGa4Args,
  TrackExpressCheckoutGa4Args,
  TrackOrderCompanySelectorGa4Args,
} from "./interfaces";

export function buildSalesflowInitiatePayload(salesInitiatePayload: TrackSalesflowInitiateGa4Args) {
  return buildDefaultGa4Payload(salesInitiatePayload);
}

export function buildPayloadBrowse(browsePayload: BrowsePayloadArgs) {
  return {
    ...buildDefaultGa4Payload(browsePayload),
    ecommerce: buildEcommercePayloadBrowse(
      browsePayload.listName,
      browsePayload.products,
      browsePayload.productIndex
    ),
  };
}

export function buildPayloadConfiguration(configPayload: ConfigurationPayloadArgs) {
  return {
    ...buildDefaultGa4Payload(configPayload),
    ecommerce: buildEcommerceValuePayload(
      configPayload.listName,
      configPayload.products,
      configPayload.totalPrice
    ),
  };
}

export function buildCartPayloadConfiguration(configPayload: ConfigurationPayloadArgs) {
  return {
    ...buildDefaultGa4Payload(configPayload),
    ecommerce: buildEcommerceCartPayload(
      configPayload.listName,
      configPayload.products,
      configPayload.totalPrice
    ),
  };
}

export function buildPayloadPurchase(purchasePayload: TrackPurchaseGa4Args) {
  const ecommerceValuePayload =
    purchasePayload.siteVersion === "mc_online"
      ? buildEcommerceCartPayload(
          purchasePayload.listName,
          purchasePayload.products,
          purchasePayload.totalPrice
        )
      : buildEcommerceValuePayload(
          purchasePayload.listName,
          purchasePayload.products,
          purchasePayload.totalPrice
        );
  return {
    ...buildDefaultGa4Payload(purchasePayload),
    ecommerce: {
      ...ecommerceValuePayload,
      transaction_id: purchasePayload.orderId,
      payment_type: "invoice",
    },
  };
}

export function buildPayloadBeginCheckout(checkoutPayload: CheckoutPayloadArgs) {
  return {
    ...buildDefaultGa4Payload(checkoutPayload),
    step_label: checkoutPayload.stepLabel,
    step_number: checkoutPayload.stepNumber,
    ecommerce: buildEcommerceValuePayload(
      checkoutPayload.listName,
      checkoutPayload.products,
      checkoutPayload.totalPrice
    ),
  };
}

export function buildPayloadRemoveProductFromCart(removeCartPayload: TrackRemoveFromCartGa4Args) {
  return {
    ...buildDefaultGa4Payload({ ...removeCartPayload }),
    ecommerce: buildEcommerceCartPayload(
      removeCartPayload.listName,
      removeCartPayload.products,
      removeCartPayload.totalPrice
    ),
  };
}

function buildDefaultGa4Payload({
  isLoggedIn,
  pageSegment,
  agreementType,
  siteVersion,
  funnelGroup,
}: DefaultPayloadArgs): CommonGa4Payload {
  const ga4AgreementMap: Record<AgreementType, Ga4AgreementType> = {
    [AgreementType.MFA]: Ga4AgreementType.MFA,
    [AgreementType.NFA]: Ga4AgreementType.NFA,
    [AgreementType.SA]: Ga4AgreementType.SA,
    [AgreementType.RAM]: Ga4AgreementType.RAM,
  };
  const siteVersionObject = siteVersion ? { site_version: siteVersion } : {};
  return {
    business_area: "b2b",
    logged_in: isLoggedIn,
    page_segment: pageSegment,
    page_type: "order",
    agreement_type: ga4AgreementMap[agreementType],
    funnel_group: funnelGroup,
    ...siteVersionObject,
  };
}
function buildEcommerceCartPayload(
  listName: string,
  productList: Ga4Product[],
  totalPrice: number | undefined
) {
  return {
    value: totalPrice,
    currency: "SEK",
    items: productList.map(getEcommerceItemMapperWithPrice(listName)),
  };
}
function buildEcommercePayloadBrowse(
  listName: string,
  productList: Ga4Product[],
  productIndex?: number
): EcommerceListName {
  return {
    item_list_name: listName,
    items: productList.map(getEcommerceItemMapperWithPrice(listName, productIndex)),
  };
}

function buildEcommerceValuePayload(
  listName: string,
  productList: Ga4Product[],
  totalPrice: number
): EcommerceValue {
  return {
    currency: "SEK",
    value: totalPrice,
    items: productList.map(getEcommerceItemMapper(listName)),
  };
}

export function buildPayloadFunnelFail(tunnelFailPayload: TrackFunnelFailGa4Args) {
  return {
    ...buildDefaultGa4Payload(tunnelFailPayload),
    funnel_group: tunnelFailPayload.funnelGroup ?? "bredband",
    error_message: tunnelFailPayload.errorMessage,
    value: tunnelFailPayload.totalPrice,
  };
}

export function buildCheckoutStepPayload(trackCheckoutArgs: TrackCheckoutGa4Args) {
  const paymentType = (trackCheckoutArgs as TrackPaymentInfoGa4Args).paymentType;
  return {
    ...buildDefaultGa4Payload(trackCheckoutArgs),
    step_label: trackCheckoutArgs.stepLabel,
    step_number: trackCheckoutArgs.stepNumber,
    items: trackCheckoutArgs.products.map(getEcommerceItemMapper("")),
    payment_type: paymentType,
  };
}

export function buildSurveyInitiatePayload(trackSurveyInitiateArgs: TrackSurveyInitiateGa4Args) {
  return {
    ...buildDefaultGa4Payload(trackSurveyInitiateArgs),
    click_action: trackSurveyInitiateArgs.clickAction,
    click_text: trackSurveyInitiateArgs.clickText,
  };
}

export function buildLoginInitiatePayload(trackLoginInitiateArgs: TrackLoginInitiateGa4Args) {
  return {
    ...buildDefaultGa4Payload(trackLoginInitiateArgs),
    login_target: trackLoginInitiateArgs.loginTarget,
    step_label: trackLoginInitiateArgs.stepLabel,
  };
}

export function buildExpressCheckoutPayload(trackExpressCheckoutArgs: TrackExpressCheckoutGa4Args) {
  return {
    ...buildDefaultGa4Payload(trackExpressCheckoutArgs),
    step_option: trackExpressCheckoutArgs.stepOption,
    step_label: trackExpressCheckoutArgs.stepLabel,
  };
}

export function buildOrderCompanySelectorPayload(
  trackOrderCompanySelectorArgs: TrackOrderCompanySelectorGa4Args
) {
  return {
    ...buildDefaultGa4Payload(trackOrderCompanySelectorArgs),
    step_label: trackOrderCompanySelectorArgs.stepLabel,
  };
}

function getEcommerceItemMapperWithPrice(
  itemListName: string,
  productIndex?: number
): (product: Ga4Product, listIndex: number) => EcommerceItem {
  return function toEcommerceItem(product: Ga4Product, listIndex: number): EcommerceItem {
    const listName = product.itemListName ?? itemListName ?? "n/a";
    return {
      item_id: product.id,
      item_name: product.name,
      currency: "SEK",
      contract_type: product.newOrExtend ?? "n/a",
      commitment: product.commitment ?? 0,
      discount: product.discount ?? 0,
      index: productIndex ?? listIndex,
      item_brand: product.brand,
      item_category: "business",
      item_category2: product.category,
      item_category3: product.subCategory,
      variant: product.variant ?? "n/a",
      item_list_name: listName,
      price: product.price,
      quantity: product.quantity,
      monthly_installment: product.recurringPrice,
    };
  };
}

function getEcommerceItemMapper(
  itemListName: string,
  productIndex?: number
): (product: Ga4Product, listIndex: number) => EcommerceItem {
  return function toEcommerceItem(product: Ga4Product, listIndex: number): EcommerceItem {
    const listName = product.itemListName ?? itemListName ?? "n/a";

    return {
      item_id: product.id,
      item_name: product.name,
      currency: "SEK",
      contract_type: product.newOrExtend ?? "n/a",
      index: productIndex ?? listIndex,
      item_brand: product.brand,
      item_category: "business",
      item_category2: product.category,
      item_category3: product.subCategory,
      item_list_name: listName,
      quantity: product.quantity,
      variant: product.variant ?? "n/a",
      commitment: product.commitment ?? 0,
    };
  };
}
