import { AxiosHeaders, AxiosResponse, GetScopeDetailsResponse } from "./types";
import http from "@telia/b2x-http-client";

export async function getUserAndHeaders(
  scopeId: string
): Promise<AxiosHeaders & GetScopeDetailsResponse> {
  const { headers, data } = await getUser(scopeId);
  return { headers, ...data };
}

export async function getNoScopeHeaders(): Promise<AxiosHeaders> {
  const response = await getIsLoggedIn();
  if (!response) throw new Error("Could not get headers");

  return { headers: response.headers };
}

export async function getUser(scopeId: string): Promise<AxiosResponse<GetScopeDetailsResponse>> {
  return http.get(`/.api/corp-scope-information/${scopeId}`);
}

export async function getIsLoggedIn(): Promise<AxiosHeaders | undefined> {
  try {
    return await http.get("/.api/corp-login/isloggedin");
  } catch (e: any) {
    return e?.response;
  }
}
