import { Environment, AxiosHeaders } from "./types";

export function getEnvironment(headers: AxiosHeaders): Environment {
  return getCurrentEnvironmentFromHeaders(headers);
}
function getCurrentEnvironmentFromHeaders({ headers }: AxiosHeaders): Environment {
  const host = window.location.host;
  if (host.includes("localhost")) {
    return Environment.DEV;
  }
  if (headers?.testenv || isTestCots(host) || isTestEnvUrl(host)) {
    return Environment.TEST;
  }
  return Environment.PROD;
}

function isTestCots(host: string): boolean {
  return ["mite.test.cots", "at.test.cots", "sit.test.cots", "dev.test.cots"].some((h) =>
    host.includes(h)
  );
}
function isTestEnvUrl(host: string): boolean {
  return ["dev.test.telia.se"].some((h) => host.includes(h));
}
